// import GQLClient from '../../graphql';
// import { gql } from '@apollo/client';
import {
  RESET_STATE,
  SET_EVENT_TYPE,
  SET_COMPETITION,
  SET_EXCH_EVENT,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  UPDATE_SECONDARY_MARKETS,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_SCORECARD,
  UPDATE_BOOKMAKER_MARKETS,
  UPDATE_FANCY_MARKETS,
  UPDATE_TOPIC_URLS,
  FETCH_EVENT_BY_COMPETITION_FAILED,
  SUSPENDED_MARKETS,
  DISABLED_MARKETS
} from './exchangeSportsActionTypes';
import { SelectedObj } from '../../models/ExchangeSportsState';
import { EventDTO } from '../../models/common/EventDTO';
import { PROVIDER_ID } from '../../constants/Branding';
import API_V2 from '../../svls-api';

// redux Actions

export const resetExchangeState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

// EventTypes
export const setEventType = (eventType: SelectedObj) => {
  return {
    type: SET_EVENT_TYPE,
    payload: eventType,
  };
};

// Competitions
export const setCompetition = (competition: SelectedObj) => {
  return {
    type: SET_COMPETITION,
    payload: competition,
  };
};

// Events
const fetchEventByCompetitionSuccess = (result) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: result,
  };
};

const fetchEventByCompetitionFailed = () => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_FAILED,
  };
};

export const setExchEvent = (event: SelectedObj) => {
  return {
    type: SET_EXCH_EVENT,
    payload: event,
  };
};

export const updateTopicUrls = (payload) => {
  return {
    type: UPDATE_TOPIC_URLS,
    payload: payload,
  };
};

const updateTopicUrlsInStore = (dispatch: Function, eventData: any) => {
  // Dispatch topic urls
  const topicUrlPayload = {
    matchOddsBaseUrl: eventData?.markets?.matchOddsBaseUrl,
    matchOddsTopic: eventData?.markets?.matchOddsTopic,
    bookMakerBaseUrl: eventData?.markets?.bookMakerBaseUrl,
    bookMakerTopic: eventData?.markets?.bookMakerTopic,
    fancyBaseUrl: eventData?.markets?.fancyBaseUrl,
    fancyTopic: eventData?.markets?.fancyTopic,
    premiumBaseUrl: eventData?.markets?.premiumBaseUrl,
    premiumTopic: eventData?.markets?.premiumTopic,
  }
  console.log("urls are ", topicUrlPayload)
  dispatch(updateTopicUrls(topicUrlPayload));
}

export const fetchEvent = (
  sportId: string,
  competitionId: string,
  eventId: string,
  marketTime?: string
) => {
  return async (dispatch: Function) => {
    try {
      if (eventId !== '') {
        let response = null;
        var eventDetails = [{
          providerId: PROVIDER_ID,
          sportId: sportId,
          competitionId: '*',
          eventId: eventId,
          marketTime: marketTime,
        }];
        if (sessionStorage.getItem('jwt_token')) {
          response = await API_V2.post(
            `/catalog/v2/sports-feed/sports/markets`,
            {
              providerId: PROVIDER_ID,
              marketsCriteria: 'ALL',
              eventDetails: eventDetails,
            },{
              headers: {
                Authorization: sessionStorage.getItem('jwt_token'),
              },
            },
          );
        } else {
          response = await API_V2.post(
            `/catalog/v2/sports-feed/sports/markets`,
            {
              providerId: PROVIDER_ID,
              marketsCriteria: 'ALL',
              eventDetails: eventDetails,
            }
          );
        }

        const eventData = response.data[0];
        if (eventData.eventId === '') {
          dispatch(fetchEventByCompetitionFailed());
        } else {
          updateTopicUrlsInStore(dispatch, eventData);
          const eData: EventDTO = {
            enabled: eventData.enabled,
          openDate: eventData.openDate,
            markets: eventData.markets,
          sportId: eventData.sportId,
            competitionId: eventData.competitionId,
            competitionName: eventData.competitionName
            ? eventData.competitionName
            : 'Other',
            eventId: eventData.eventId,
            eventName: eventData.eventName,
            marketId: eventData.marketId,
            providerName: eventData.providerName,
            status: eventData.status,
        };

          const payload = {
            eventData: eData,
            sportId: eData.sportId,
            competitionId: eData.competitionId,
            matchOddsData:
              eventData.markets && eventData.markets.matchOdds
                ? eventData.markets.matchOdds.find(
                    (mo) =>
                      mo.marketName === 'Match Odds' ||
                      mo.marketName.toLowerCase() === 'moneyline'
                  )
                : null,
            raceMarkets:
              eventData.markets && eventData.markets.matchOdds
                ? eventData.markets.matchOdds
                : [],
            onRefresh: true,
          };

          dispatch(fetchEventByCompetitionSuccess(payload));
          if (
            ['1', '2'].includes(eData.sportId) ||
            eData.sportId === 'football' ||
            eData.sportId === 'tennis'
          ) {
            for (let mo of eventData.markets.matchOdds) {
              if (
                mo.marketName !== 'Match Odds' &&
                mo.marketName.toLowerCase() !== 'moneyline'
              ) {
                const secMOPayload = {
                  eventId: eventData.eventId,
                  marketId: mo.marketId,
                  matchOddsData: mo,
                };
                dispatch(updateSecondaryMatchOdds(secMOPayload));
              }
            }
          }
          if (eData.sportId === '4') {
            const secMarketsPayload = {
              eventId: eventData.eventId,
              bookmakerOddsData:
                eventData.markets && eventData.markets.bookmakers
                  ? eventData.markets.bookmakers
                  : null,
              sessionOddsData: 
                eventData.markets && eventData.markets.fancyMarkets
                  ? eventData.markets.fancyMarkets
                  : null,
              fancySuspended : eventData.markets
                ? eventData.markets.fancySuspended
                :false,
              fancyDisabled : eventData.markets
                ? eventData.markets.fancyDisabled
                :false,
            };
            dispatch(updateSecondaryMarkets(secMarketsPayload));
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const updateOddsfromWS = (payload) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: payload,
  };
};

export const updateSecondaryMarkets = (payload) => {
  return {
    type: UPDATE_SECONDARY_MARKETS,
    payload: payload,
  };
};

export const updateSecondaryMatchOdds = (payload) => {
  return {
    type: UPDATE_SECONDARY_MATCH_ODDS,
    payload: payload,
  };
};


export const updateSuspendedMarkets = (payload) => {
  return {
    type: SUSPENDED_MARKETS,
    payload: payload,
  };
};

export const updateDisabledMarkets = (payload) => {
  return {
    type: DISABLED_MARKETS,
    payload: payload,
  };
};


export const updateEventScorecard = (payload) => {
  return {
    type: UPDATE_SCORECARD,
    payload: payload,
  };
};

export const updateFancyMarkets = (payload) => {
  return {
    type: UPDATE_FANCY_MARKETS,
    payload: payload,
  };
};

export const updateBookMakerMarkets = (payload) => {
  return {
    type: UPDATE_BOOKMAKER_MARKETS,
    payload: payload,
  };
};
