import {
  RESET_STATE,
  SET_EVENT_TYPE,
  SET_COMPETITION,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  SET_EXCH_EVENT,
  UPDATE_SCORECARD,
  UPDATE_SECONDARY_MARKETS,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_TOPIC_URLS,
  FETCH_EVENT_BY_COMPETITION_FAILED,
  SUSPENDED_MARKETS,
  DISABLED_MARKETS,
} from './exchangeSportsActionTypes';

import { ExchangeSportsState, SecondaryMarkets, SecondaryMarketsMap } from '../../models/ExchangeSportsState';
import {
  BookmakerDTO,
  BookmakerRunnerDTO,
} from '../../models/common/BookmakerDTO';
import { FancyMarketDTO } from '../../models/common/FancyMarketDTO';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import { ExchangePriceDTO } from '../../models/common/ExchangePriceDTO';
import { EVENT_TYPES } from '../../constants/EventTypes';
import { DisabledMarketDTO, DisabledMarketDTOMap, SuspendedMarketDTO, SuspendedMarketDTOMap } from '../../models/common/SuspendedMarketDTO';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExchangeSportsState = {
  eventTypes: EVENT_TYPES,
  selectedEventType: { id: '4', name: 'Cricket', slug: 'cricket' },
  competitions: {},
  selectedCompetition: { id: '', name: '', slug: '' },
  events: {},
  selectedEvent: { id: '', name: '', slug: '' },
  secondaryMatchOddsMap: {},
  secondaryMarketsMap: {},
  scorecard: null,
  topicUrls: {
    matchOddsBaseUrl: '',
    matchOddsTopic: '',
    bookMakerBaseUrl: '',
    bookMakerTopic: '',
    fancyBaseUrl: '',
    fancyTopic: '',
    premiumBaseUrl: '',
    premiumTopic: ''
  },
  suspendedMarketsMap: {},
  disabledMarketsMap: {},
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
  let pricesSet: ExchangePriceDTO[] = [];
  for (let i = 0; i < 3; i += 1) {
    if (prices[i]) pricesSet.push(prices[i]);
    else pricesSet.push({ price: null, size: null });
  }
  return pricesSet;
};

const getFancyDisabledValue = (
  disableMarketsMap: DisabledMarketDTOMap,
  providerId: string,
  sportId: string,
  competitionId: string,
  dtoEventId: string,
  dtoMarketType: string,
  dtoFancyDisable: boolean
) => {
  let fancyDisable: boolean;
  let key = dtoEventId + ':' + '*';
  if (disableMarketsMap[key]) {
    // case for odds updates from websockets
    // disable field will not be present in odds update from websocket
    let disabledMarket = disableMarketsMap[key];
    if (!disabledMarket) {
      fancyDisable = false;
    } else if (disabledMarket) {
      fancyDisable = disabledMarket.disable;
    }
  } else {
    fancyDisable = dtoFancyDisable;
    disableMarketsMap[key] = {
      providerId: providerId,
      sportId: sportId,
      competitionId: competitionId,
      eventId: dtoEventId,
      marketType: dtoMarketType,
      marketId: '*',
      disable: dtoFancyDisable,
    };
  }
  return fancyDisable;
};

const getFancySuspendedValue = (
  suspendMarketsMap: SuspendedMarketDTOMap,
  providerId: string,
  sportId: string,
  competitionId: string,
  dtoEventId: string,
  dtoMarketType: string,
  dtoFancySuspend: boolean
) => {
  let fancySuspend: boolean;
  let key = dtoEventId + ':' + '*';
  if (suspendMarketsMap[key]) {
    // case for odds updates from websockets
    // suspend field will not be present in odds update from websocket
    let suspendedMarket = suspendMarketsMap[key];
    if (!suspendedMarket) {
      fancySuspend = false;
    } else if (suspendedMarket) {
      fancySuspend = suspendedMarket.suspend;
    }
  } else {
    fancySuspend = dtoFancySuspend;
    suspendMarketsMap[key] = {
      providerId: providerId,
      sportId: sportId,
      competitionId: competitionId,
      eventId: dtoEventId,
      marketType: dtoMarketType,
      marketId: '*',
      suspend: dtoFancySuspend,
    };
  } 
  return fancySuspend;
};

const getDisableValue = (
  disableMarketsMap: DisabledMarketDTOMap,
  providerId: string,
  sportId: string,
  competitionId: string,
  dtoEventId: string,
  dtoMarketType: string,
  dtoMarketId: string,
  dtoDisable: boolean
) => {
  let disable: boolean;
  let key = dtoEventId + ':' + dtoMarketId;
  // case: for storing initial state from markets api call
  if (dtoDisable !== undefined) {
    disable = dtoDisable;
    disableMarketsMap[key] = {
      providerId: providerId,
      sportId: sportId,
      competitionId: competitionId,
      eventId: dtoEventId,
      marketType: dtoMarketType,
      marketId: dtoMarketId,
      disable: dtoDisable,
    };
  } else {
    // case for odds updates from websockets
    // disable field will not be present in odds update from websocket
    let disabledMarket = disableMarketsMap[key];
    if (!disabledMarket) {
      disable = false;
    } else if (disabledMarket) {
      disable = disabledMarket.disable;
    }
  }

  return disable;
};

const getSuspendValue = (
  suspendMarketsMap: SuspendedMarketDTOMap,
  providerId: string,
  sportId: string,
  competitionId: string,
  dtoEventId: string,
  dtoMarketType: string,
  dtoMarketId: string,
  dtoSuspsend: boolean
) => {
  let suspend: boolean;
  let key = dtoEventId + ":" + dtoMarketId;

  // case: for storing initial state from markets api call
  if (dtoSuspsend !== undefined) {
    suspend = dtoSuspsend;
    suspendMarketsMap[key] = {
      providerId: providerId,
      sportId: sportId,
      competitionId: competitionId,
      eventId: dtoEventId,
      marketType: dtoMarketType,
      marketId: dtoMarketId,
      suspend: dtoSuspsend,
    };
  } else { 
    // case for odds updates from websockets
    // suspend field will not be present in odds update from websocket
    let suspendedMarket = suspendMarketsMap[key];
    if (!suspendedMarket) {
      suspend = false;
    } else if (suspendedMarket) {
      suspend = suspendedMarket.suspend;
    }
  }

  return suspend;
};

const exchangeSportsReducer = (
  state = initialState,
  action: Action
): ExchangeSportsState => {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...state,
        eventTypes: [],
        selectedEventType: { id: '1', name: 'Football', slug: 'football' },
        competitions: {},
        selectedCompetition: { id: '', name: '', slug: '' },
        events: {},
      };
    }

    case SET_EVENT_TYPE: {
      return {
        ...state,
        selectedEventType: action.payload,
      };
    }

    case SET_COMPETITION: {
      return {
        ...state,
        selectedCompetition: action.payload,
      };
    }

    case SUSPENDED_MARKETS: {
      let suspendedMarket: SuspendedMarketDTO = action.payload;
      let allSuspendedMarketsMap: SuspendedMarketDTOMap = { ...state.suspendedMarketsMap };
      const sportId = suspendedMarket.sportId;
      const competitionId = suspendedMarket.competitionId; 
      const eventId = suspendedMarket.eventId;
      const marketType = suspendedMarket.marketType;
      const marketId = suspendedMarket.marketId;
      const key = eventId + ":" + marketId;
      allSuspendedMarketsMap[key] = suspendedMarket;

      console.log("I am here 1")
      // TODO: check if the return statement is required or not ??
      switch (marketType) {
        case "MATCH_ODDS": {
          // Set suspend in match odds markets data
          const allEvents = { ...state.events };
          if (eventId && allEvents[sportId][competitionId][eventId]){
            let matchOddsMarket = allEvents[sportId][competitionId][eventId]?.matchOdds;
            if (matchOddsMarket.marketId === marketId) {
              matchOddsMarket.suspend = suspendedMarket.suspend;
            }
          }

          // For secondary match odds data
          const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };
          if (allSecMatchOddsMap[eventId + '-' + marketId]) {
            let matchOddsMarket = allSecMatchOddsMap[eventId + '-' + marketId];
            matchOddsMarket.suspend = suspendedMarket.suspend;
          }
          break;
        }
        case "BOOKMAKER": {
          // Set suspend in bookmaker markets data
          let secondaryMarketsMap: SecondaryMarketsMap = { ...state.secondaryMarketsMap};
          let secondaryMarkets: SecondaryMarkets = secondaryMarketsMap[eventId];
          let bookmakerMarkets: BookmakerDTO[] = secondaryMarkets.bookmakers;
          if (bookmakerMarkets && bookmakerMarkets.length) {
            for (let bm of bookmakerMarkets) {
              if (bm.marketId === marketId) bm.suspend = suspendedMarket.suspend;
            }
          }
          break;
        }
        case "FANCY": {
          // Set suspend in fancy markets data
          let secondaryMarketsMap: SecondaryMarketsMap = { ...state.secondaryMarketsMap};
          let secondaryMarkets: SecondaryMarkets = secondaryMarketsMap[eventId];
          let fancyMarkets: FancyMarketDTO[] = secondaryMarkets.fancyMarkets;
          if (fancyMarkets && fancyMarkets.length) {
            if (marketId === '*') {
              secondaryMarkets.fancySuspended = suspendedMarket.suspend;
            } else {
              for (let f of fancyMarkets) {
                if (f.selectionId === marketId) f.suspend = suspendedMarket.suspend;
              }
            }
          }
          break;
        }
        default:
          console.log("SuspendMarket: Invalid market type: ", marketType);
      }     

      return {
        ...state,
        suspendedMarketsMap: allSuspendedMarketsMap
      };
    }

    case DISABLED_MARKETS: {
      let disabledMarket: DisabledMarketDTO = action.payload;
      let allDisabledMarketsMap: DisabledMarketDTOMap = {
        ...state.disabledMarketsMap,
      };
      const sportId = disabledMarket.sportId;
      const competitionId = disabledMarket.competitionId;
      const eventId = disabledMarket.eventId;
      const marketType = disabledMarket.marketType;
      const marketId = disabledMarket.marketId;
      const key = eventId + ':' + marketId;
      allDisabledMarketsMap[key] = disabledMarket;

      // TODO: check if the return statement is required or not ??
      switch (marketType) {
        case 'MATCH_ODDS': {
          // Set disable in match odds markets data
          const allEvents = { ...state.events };
          if (eventId && allEvents[sportId][competitionId][eventId]) {
            let matchOddsMarket =
              allEvents[sportId][competitionId][eventId]?.matchOdds;
            if (matchOddsMarket.marketId === marketId) {
              matchOddsMarket.disable = disabledMarket.disable;
            }
          }

          // For secondary match odds data
          const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };
          if (allSecMatchOddsMap[eventId + '-' + marketId]) {
            let matchOddsMarket = allSecMatchOddsMap[eventId + '-' + marketId];
            matchOddsMarket.disable = disabledMarket.disable;
          }
          break;
        }
        case 'BOOKMAKER': {
          // Set disable in bookmaker markets data
          let secondaryMarketsMap: SecondaryMarketsMap = {
            ...state.secondaryMarketsMap,
          };
          let secondaryMarkets: SecondaryMarkets = secondaryMarketsMap[eventId];
          let bookmakerMarkets: BookmakerDTO[] = secondaryMarkets.bookmakers;
          if (bookmakerMarkets && bookmakerMarkets.length) {
            for (let bm of bookmakerMarkets) {
              if (bm.marketId === marketId) bm.disable = disabledMarket.disable;
            }
          }
          break;
        }
        case 'FANCY': {
          // Set disable in fancy markets data
          let secondaryMarketsMap: SecondaryMarketsMap = {
            ...state.secondaryMarketsMap,
          };
          let secondaryMarkets: SecondaryMarkets = secondaryMarketsMap[eventId];
          let fancyMarkets: FancyMarketDTO[] = secondaryMarkets.fancyMarkets;
          if (fancyMarkets && fancyMarkets.length) {
            if (marketId === '*') {
              secondaryMarkets.fancyDisabled = disabledMarket.disable;
            } else {
              for (let f of fancyMarkets) {
                if (f.selectionId === marketId) f.disable = disabledMarket.disable;
              }
            }
          }
          break;
        }
        default:
          console.log('DisableMarket: Invalid market type: ', marketType);
      }

      return {
        ...state,
        disabledMarketsMap: allDisabledMarketsMap,
      };
    }

    case FETCH_EVENT_BY_COMPETITION_SUCCESS: {
      const eId: string = action.payload.sportId;
      const cId: string = action.payload.competitionId;
      const eventId: string = action.payload.eventId;

      // Add events map hierarchy
      const allEvents = { ...state.events };
      if (!allEvents[eId]) {
        allEvents[eId] = {};
      }
      if (!allEvents[eId][cId]) {
        allEvents[eId][cId] = {};
      }

      let suspendMarketsMap: SuspendedMarketDTOMap = { ...state.suspendedMarketsMap };
      let disableMarketsMap: DisabledMarketDTOMap = { ...state.disabledMarketsMap };
      // Add events data
      if (action.payload.eventData) {
        let eData;
        if (eventId && allEvents[eId][cId][eventId])
          eData = { ...allEvents[eId][cId][eventId] };
        else eData = { ...action.payload.eventData };

        let homeTeam = eData.homeTeam ? eData.homeTeam : '';
        let awayTeam = eData.awayTeam ? eData.awayTeam : '';
        if (
          homeTeam === '' &&
          awayTeam === '' &&
          eData.eventName?.toLowerCase().includes(' v ')
        ) {
          homeTeam = eData.eventName.split(' v ')[0].trim();
          awayTeam = eData.eventName.split(' v ')[1].trim().split(' - ')[0];
        }

        // Set MatchOdds Data.
        const matchOddsData = action.payload.matchOddsData;
        const runners: MatchOddsRunnerDTO[] = [];
        let suspend: boolean = getSuspendValue(
          suspendMarketsMap,
          eData?.providerId,
          eData?.sportId,
          eData?.competitionId,
          eData?.eventId,
          eData?.marketType,
          eData?.marketId,
          matchOddsData?.suspended
        );
        let disable: boolean = getDisableValue(
          disableMarketsMap,
          eData?.providerId,
          eData?.sportId,
          eData?.competitionId,
          eData?.eventId,
          eData?.marketType,
          eData?.marketId,
          matchOddsData?.disabled
        );     
        if (matchOddsData) {
          if (
            matchOddsData.runners &&
            matchOddsData.runners.length > 0
          ) {
            let i = 0;
            for (let e of matchOddsData.runners) {
              if (e) {
                const runnerName = e.runnerName ? e.runnerName : e.RunnerName;

                if (
                  i === 0 &&
                  !runnerName.toLowerCase().includes('draw') &&
                  runnerName !== awayTeam
                ) {
                  homeTeam = runnerName;
                }

                if (
                  i !== 0 &&
                  !runnerName.toLowerCase().includes('draw') &&
                  runnerName !== homeTeam
                ) {
                  awayTeam = runnerName;
                }
                i += 1;
                runners.push({
                  runnerId: e.runnerId ? e.runnerId : "",
                  runnerName: runnerName,
                  backPrices: e.backPrices ? getMatchOddsSet(e.backPrices) : [],
                  layPrices: e.layPrices ? getMatchOddsSet(e.layPrices) : [],
                  status: e.status,
                });
              }
            }
          }
          eData.matchOdds = {
            marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
            marketName: matchOddsData.marketName
              ? matchOddsData.marketName
              : '',
            status: matchOddsData.status ? matchOddsData.status : '',
            runners: runners,
            suspend: suspend,
            disable: disable,
          };
        } else {
          let matchOdds = eData.matchOdds;
          if (matchOdds) {
            eData.matchOdds = {
              marketId: matchOdds.marketId,
              marketName: matchOdds.marketName,
              status: 'SUSPENDED',
              runners: matchOdds.runners,
              suspend: true,
              disable: true,
            };
          } else {
            eData.matchOdds = {
              marketId: '',
              marketName: '',
              inplay: false,
              status: 'SUSPENDED',
              runners: [],
              suspend: true,
              disable: true,
            };
          }
        }

        if (action.payload.raceMarkets?.length > 0) {
          let markets = [];
          for (const rm of action.payload.raceMarkets) {
            const runners: MatchOddsRunnerDTO[] = [];
            let suspend: boolean = getSuspendValue(
              suspendMarketsMap,
              eData?.providerId,
              eData?.sportId,
              eData?.competitionId,
              eData?.eventId,
              eData?.marketType,
              rm.marketId,
              rm?.suspended,
            );  
            let disable: boolean = getDisableValue(
              disableMarketsMap,
              eData?.providerId,
              eData?.sportId,
              eData?.competitionId,
              eData?.eventId,
              eData?.marketType,
              rm.marketId,
              rm?.disabled,
            );  
            if (rm.runners && rm.runners.length > 0) {
              let i = 0;
              for (let e of rm.runners) {
                if (e) {
                  i += 1;
                  runners.push({
                    runnerId: e.runnerId,
                    runnerName: e.runnerName,
                    backPrices: getMatchOddsSet(e.backPrices),
                    layPrices: getMatchOddsSet(e.layPrices),
                    // clothNumber: rm?.clothNumber,
                    // jockeyName: rm?.jockeyName,
                    // runnerAge: rm?.runnerAge,
                    // runnerIcon: rm?.runnerIcon,
                    // stallDraw: rm?.stallDraw,
                    // trainerName: rm?.trainerName,
                    status: e.status,
                  });
                }
              }
            }
            markets.push({
              marketId: rm.marketId,
              marketName: rm.marketName,
              marketTime: rm.marketTime,
              status: rm.status,
              runners: runners,
              suspend: suspend,
              disable: disable,
            });
          }
          markets.sort((a, b) => {
            const aDesc = a.marketTime;
            const bDesc = b.marketTime;
            if (aDesc > bDesc) return 1;
            else if (aDesc < bDesc) return -1;
            else return 0;
          });
          eData.raceMarkets = markets;
        }

        // Set EventData
        eData.homeTeam = homeTeam;
        eData.awayTeam = awayTeam;
        eData.eventSlug = eData.eventSlug
          ? eData.eventSlug
          : eData.eventName
              .toLowerCase()
              .replace(/[^a-z0-9]/g, ' ')
              .replace(/ +/g, ' ')
              .trim()
              .split(' ')
              .join('-');

        allEvents[eId][cId][eData.eventId] = eData;
      }
      if (action.payload.onRefresh) {
        let currentSelectedCompetition;
        let currentSelectedEvent;
        let currentSelectedEventType;

        try {
          currentSelectedEventType =
            state.selectedEventType.id === eId
              ? { ...state.selectedEventType }
              : {
                  id: eId,
                  name: EVENT_TYPES.filter((e) => e.id === eId)[0].name,
                  slug: EVENT_TYPES.filter((e) => e.id === eId)[0].slug,
                };
          currentSelectedCompetition =
            state.selectedCompetition.id === cId
              ? { ...state.selectedCompetition }
              : {
                  id: action.payload.eventData?.competitionId
                    ? action.payload.eventData.competitionId
                    : '',
                  name: action.payload.eventData?.competitionName
                    ? action.payload.eventData.competitionName
                    : '',
                  slug: action.payload.eventData?.competitionName
                    ? action.payload.eventData.competitionName
                        .toLocaleLowerCase()
                        .replace(/[^a-z0-9]/g, ' ')
                        .replace(/ +/g, ' ')
                        .trim()
                        .split(' ')
                        .join('-')
                    : '',
                };

          currentSelectedEvent =
            state.selectedEvent.id === action.payload.eventData?.eventId
              ? { ...state.selectedEvent }
              : {
                  id: action.payload.eventData?.eventId,
                  slug: action.payload.eventData?.eventName
                    .toLocaleLowerCase()
                    .replace(/[^a-z0-9]/g, ' ')
                    .replace(/ +/g, ' ')
                    .trim()
                    .split(' ')
                    .join('-'),
                  name: action.payload.eventData?.eventName,
                };
        } catch (_) {
          currentSelectedCompetition = { ...state.selectedCompetition };
          currentSelectedEvent = { ...state.selectedEvent };
          currentSelectedEventType = { ...state.selectedEventType };
        }
        return {
          ...state,
          events: allEvents,
          selectedEventType: currentSelectedEventType,
          selectedCompetition: currentSelectedCompetition,
          selectedEvent: currentSelectedEvent,
          suspendedMarketsMap: suspendMarketsMap,
          disabledMarketsMap: disableMarketsMap,
        };
      }

      return {
        ...state,
        events: allEvents,
      };
    }

    case FETCH_EVENT_BY_COMPETITION_FAILED: {
      return {
        ...state,
        events: {},
        secondaryMarketsMap: {},
      };
    }

    case UPDATE_SECONDARY_MATCH_ODDS: {
      const eventData = action.payload;
      const eventId: string = eventData.eventId;
      const marketId: string = action.payload.marketId;
      let suspendMarketsMap: SuspendedMarketDTOMap = { ...state.suspendedMarketsMap };
      let disableMarketsMap: DisabledMarketDTOMap = { ...state.disabledMarketsMap };

      const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };

      const matchOddsData = action.payload.matchOddsData;
      const runners: MatchOddsRunnerDTO[] = [];
      if (matchOddsData) {
        let suspend: boolean = getSuspendValue(
          suspendMarketsMap,
          eventData?.providerId,
          eventData?.sportId,
          eventData?.competitionId,
          eventId,
          matchOddsData?.marketType,
          matchOddsData?.marketId,
          matchOddsData?.suspended
        ); 
        let disable: boolean = getDisableValue(
          disableMarketsMap,
          eventData?.providerId,
          eventData?.sportId,
          eventData?.competitionId,
          eventId,
          matchOddsData?.marketType,
          matchOddsData?.marketId,
          matchOddsData?.disabled
        );  
        if (
          matchOddsData.runners &&
          matchOddsData.runners.length > 0
        ) {
          for (let e of matchOddsData.runners) {
            if (e) {
              const runnerName = e.runnerName ? e.runnerName : e.RunnerName;
              runners.push({
                runnerId: e.runnerId ? e.runnerId : e.runnerId,
                runnerName: runnerName,
                backPrices: getMatchOddsSet(e.backPrices),
                layPrices: getMatchOddsSet(e.layPrices),
                status: e.status,
              });
            }
          }
        }
        allSecMatchOddsMap[eventId + '-' + marketId] = {
          marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
          marketName: matchOddsData.marketName ? matchOddsData.marketName : '',
          status: matchOddsData.status ? matchOddsData.status : '',
          runners: runners,
          suspend: suspend,
          disable: disable,
        };
      } else {
        let matchOdds = allSecMatchOddsMap[eventId + '-' + marketId];
        if (matchOdds) {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: matchOdds.marketId,
            marketName: matchOdds.marketName,
            status: 'SUSPENDED',
            runners: matchOdds.runners,
            suspend: true,
            disable: true,
          };
        } else {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: '',
            marketName: '',
            status: 'SUSPENDED',
            runners: [],
            suspend: true,
            disable: true,
          };
        }
      }

      const secMatchOddsMap = { ...state.secondaryMatchOddsMap };
      secMatchOddsMap[eventId + '-' + marketId] =
        allSecMatchOddsMap[eventId + '-' + marketId];

      return {
        ...state,
        secondaryMatchOddsMap: secMatchOddsMap,
        suspendedMarketsMap: suspendMarketsMap,
        disabledMarketsMap: disableMarketsMap,
      };
    }

    case UPDATE_SECONDARY_MARKETS: {
      const eventData = action.payload;
      const eventId: string = eventData.eventId;
      const marketsMap = { ...state.secondaryMarketsMap };
      let suspendMarketsMap: SuspendedMarketDTOMap = { ...state.suspendedMarketsMap };
      let disableMarketsMap: DisabledMarketDTOMap = { ...state.disabledMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[eventId]) {
          marketsMap[eventId] = {
            bookmakers: [],
            fancyMarkets: [],
            fancySuspended: false,
            fancyDisabled: false,
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData = action.payload.bookmakerOddsData;
        let bookMakerOdds: BookmakerDTO[] = [];
        if (bookMakerOddsData && bookMakerOddsData.length > 0) {
          for (let br of bookMakerOddsData) {
            let bmRUnners: BookmakerRunnerDTO[] = [];
            let suspend: boolean = getSuspendValue(
              suspendMarketsMap,
              eventData?.providerId,
              eventData?.sportId,
              eventData?.competitionId,
              eventId,
              br?.marketType,
              br?.marketId,
              br?.suspended,
            );  
            let disable: boolean = getDisableValue(
              disableMarketsMap,
              eventData?.providerId,
              eventData?.sportId,
              eventData?.competitionId,
              eventId,
              br?.marketType,
              br?.marketId,
              br?.disabled,
            );   
            for (let b of br.runners) {
              bmRUnners.push({
                runnerId: b.runnerId ? b.runnerId : '',	
                runnerName: b.runnerName ? b.runnerName : '',	
                backPrice: b.backPrices[0]?.price,	
                backSize: b.backPrices[0]?.size,	
                layPrice: b.layPrices[0]?.price,	
                laySize: b.layPrices[0]?.size,	
                status: b.status ? b.status : '',
              });
            }
            bmRUnners.sort((a, b) => {
              const aDesc = a.runnerId;
              const bDesc = b.runnerId;
              if (aDesc > bDesc) return 1;
              else if (aDesc < bDesc) return -1;
              else return 0;
            });
            bookMakerOdds.push({
              marketId: br.marketId ? br.marketId : '-1',
              marketName: br.marketName ? br.marketName : 'Bookmaker',
              runners: bmRUnners,
              status: br.status ? br.status : 'OPEN',
              suspend: suspend,
              disable: disable,
            });
          }
        } else if (marketsMap[eventId].bookmakers[0]) {
          bookMakerOdds = marketsMap[eventId].bookmakers;
          for (let bm of bookMakerOdds) {
            for (let br of bm.runners) {
              br.backPrice = '0';
              br.layPrice = '0';
            }
            bm.suspend = true;
            bm.disable = true;
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[eventId].bookmakers = bookMakerOdds;

        // Set Fancy markets data
        const fancyOddsData = action.payload.sessionOddsData;
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            let suspend: boolean = getSuspendValue(
              suspendMarketsMap,
              eventData?.providerId,
              eventData?.sportId,
              eventData?.competitionId,
              eventId,
              f?.marketType,
              f?.marketId,
              f?.suspended,
            );   
            let disable: boolean = getDisableValue(
              disableMarketsMap,
              eventData?.providerId,
              eventData?.sportId,
              eventData?.competitionId,
              eventId,
              f?.marketType,
              f?.marketId,
              f?.disabled,
            );
            fancyOdds.push({
              selectionId: f.marketId ? f.marketId : '',
              marketName: f.marketName ? f.marketName : '',
              status: f.status ? f.status : '',
              layPrice: f.layPrice ? f.layPrice : null,
              backPrice: f.backPrice ? f.backPrice : null,
              laySize: f.laySize ? f.laySize : null,
              backSize: f.backSize ? f.backSize : null,
              suspend: suspend,
              disable: disable
            });
          }
        } else if (marketsMap[eventId].fancyMarkets) {
          fancyOdds = marketsMap[eventId].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.status = 'SUSPENDED';
            fMarket.suspend = true;
            fMarket.disable = true;
          }
        }
        fancyOdds.sort((a, b) => {
          const aDesc = a.selectionId;
          const bDesc = b.selectionId;
          if (aDesc > bDesc) return -1;
          else if (aDesc < bDesc) return 1;
          else return 0;
        });
        marketsMap[eventId].fancyMarkets = fancyOdds;
        marketsMap[eventId].fancySuspended = action.payload.fancySuspended;
        marketsMap[eventId].fancyDisabled = action.payload.fancyDisabled;
      }
      return {
        ...state,
        secondaryMarketsMap: marketsMap,
        suspendedMarketsMap: suspendMarketsMap,
        disabledMarketsMap: disableMarketsMap,
      };
    }

    case UPDATE_SCORECARD: {
      return {
        ...state,
        scorecard: action.payload,
      };
    }

    case UPDATE_TOPIC_URLS: {
      return {
        ...state,
        topicUrls: action.payload,
      };
    }

    case SET_EXCH_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }

    default:
      return state;
  }
};

export default exchangeSportsReducer;
