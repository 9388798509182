import API from '../../api';
import REPORTING_API from '../../reporting';
import {
  EXCH_BET_ODDS_CHANGE,
  REMOVE_EXCH_BET,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  CLEAR_SUCCESS_BETS,
  FETCH_OPEN_BETS_CACHE_SUCCESS,
} from './exchBetslipActionTypes';

export const removeExchangeBet = (index: number) => {
  return {
    type: REMOVE_EXCH_BET,
    payload: index,
  };
};

export const setExchangeBetStake = (
  index: number,
  amount: number,
  type: 'ADD' | 'SET'
) => {
  return {
    type: SET_EXCH_BET_STAKE,
    payload: {
      index,
      amount,
      type,
    },
  };
};

export const exchangeBetOddsChange = (index: number, odds: number) => {
  return {
    type: EXCH_BET_ODDS_CHANGE,
    payload: { index, odds },
  };
};

export const clearExchcngeBets = () => {
  return {
    type: CLEAR_EXCHANGE_BETS,
    payload: [],
  };
};

const fetchOpenBetsCacheSuccess = (result, totalOrders?: number) => {
  return {
    type: FETCH_OPEN_BETS_CACHE_SUCCESS,
    payload: {
      result: result,
      totalOrders: totalOrders ?? 0,
    },
  };
};

export const fetchOpenBetsCache = (
  eventId: string,
  username?: string,
  providerId?: string,
  marketType?: string
) => {
  return async (dispatch: Function) => {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    let userName = JSON.parse(window.atob(claim)).owner;
    try {
      if (eventId) {
        let url = '/reports/v2/orders/:search';
        REPORTING_API.get(url, {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            Accept: 'application/json',
          },
          params: {
            reportType: 'OPEN_ORDERS',
            status: 'Open',
            providerId: providerId,
            eventId: eventId,
            userName: username ? username : null,
            marketType: marketType,
          },
        })
          .then((response) => {
            dispatch(
              fetchOpenBetsCacheSuccess(
                response.data.orders,
                response.data.totalOrders
              )
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      dispatch(fetchOpenBetsCacheSuccess([]));
    }
  };
};
export const clearSuccessBets = (successIndices: number[]) => {
  return {
    type: CLEAR_SUCCESS_BETS,
    payload: successIndices,
  };
};
