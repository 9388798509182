export const RESET_STATE = 'RESET_STATE';
export const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
export const SET_COMPETITION = 'SET_COMPETITION';
export const FETCH_EVENT_BY_COMPETITION_SUCCESS =
  'FETCH_EVENT_BY_COMPETITION_SUCCESS';
export const FETCH_EVENT_BY_COMPETITION_FAILED =
  'FETCH_EVENT_BY_COMPETITION_FAILED';
export const SET_EXCH_EVENT = 'SET_EXCH_EVENT';
export const UPDATE_SECONDARY_MARKETS = 'UPDATE_SECONDARY_MARKETS';
export const UPDATE_SECONDARY_MATCH_ODDS = 'UPDATE_SECONDARY_MATCH_ODDS';
export const UPDATE_SCORECARD = 'UPDATE_SCORECARD';
export const UPDATE_FANCY_MARKETS = 'UPDATE_FANCY_MARKETS';
export const UPDATE_BOOKMAKER_MARKETS = 'UPDATE_BOOKMAKER_MARKETS';
export const UPDATE_TOPIC_URLS = 'UPDATE_TOPIC_URLS';
export const SUSPENDED_MARKETS = 'SUSPENDED_MARKETS';
export const DISABLED_MARKETS = 'DISABLED_MARKETS';