import { createBrowserHistory } from 'history';

import API from '../../api';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  AUTH_REQUEST_START,
  AUTH_REQUEST_END,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  MAIL_VERIFIED_REQUEST_SUCCESS,
  MAIL_VERIFIED_REQUEST_FAILED,
  HANDLE_SIDE_BAR,
} from './authActionTypes';
import API_V2 from '../../svls-api';

declare const sessionStorage: any;

export const handleSideBar = () => {
  console.log('!!!nnmmnhj');
  return {
    type: HANDLE_SIDE_BAR,
  };
};

export const requestStart = () => {
  return {
    type: AUTH_REQUEST_START,
  };
};

export const requestEnd = () => {
  return {
    type: AUTH_REQUEST_END,
  };
};

export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  };
};

export const loginFailed = (err: string) => {
  return {
    type: LOGIN_FAILED,
    payload: err,
  };
};

const logoutSuccess = () => {
  return {
    type: LOG_OUT_SUCCESS,
  };
};

const logoutFailed = (err: string) => {
  return {
    type: LOG_OUT,
    payload: err,
  };
};

export const getRoleFromToken = (): string => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).role;
  }
  return null;
};

export const getPermissionFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).perm;
  }
  return null;
};

export const getCurrencyTypeFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).cur;
  }
  return null;
};

export const getHouseIdFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).hid;
  }
  return null;
};

export const fetchMailVerifiedSuccess = (mailVerified: boolean) => {
  return {
    type: MAIL_VERIFIED_REQUEST_SUCCESS,
    payload: mailVerified,
  };
};

export const fetchMailVerifiedFailed = () => {
  return {
    type: MAIL_VERIFIED_REQUEST_FAILED,
    payload: null,
  };
};

export const fetchMailVerifiedStatus = () => {
  return async (dispatch) => {
    try {
      const response = await API.get('/mail-verified', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        dispatch(fetchMailVerifiedSuccess(response.data.mailVerified));
      } else {
        dispatch(fetchMailVerifiedFailed());
      }
    } catch (ex) {
      dispatch(fetchMailVerifiedFailed());
    }
  };
};

export const login = (username: string, password: string, code: string) => {
  return async (dispatch: Function) => {
    const uuid = uniqueGuid();
    dispatch(requestStart());
    try {
      let response;
      if (code) {
        response = await API.post('/mfa/validate/key', {
          username,
          code,
          uuid,
        });
      } else {
        const loginRequest = {
          username,
          password,
          uuid,
        };
        response = await API.post('/login', loginRequest);
      }
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('jwt_token', response.data.jwtToken);
      dispatch(loginSuccess(response.data));
      const history = createBrowserHistory({ forceRefresh: true });
      let claim = response.data.jwtToken.split('.')[1];
      let permission = JSON.parse(window.atob(claim)).perm;
      let role = JSON.parse(window.atob(claim)).role;
      let status = JSON.parse(window.atob(claim)).sts;
      if (status === 2) {
        history.replace('/terms-and-conditions');
      } else if (status === 4) {
        history.replace('/reset-password');
      } else if ((permission & 2) !== 0) {
        history.replace('/platform_admin/house');
      } else if (role !== 'User') {
        history.replace('/admin');
      }
    } catch (err) {
      dispatch(loginFailed(err.response.data.message));
    }
  };
};

export const signup = (username: string, password: string) => {
  return async (dispatch: Function) => {
    dispatch(requestStart());
    try {
      const response = await API.post('/user', {
        username,
        password,
      });
      dispatch(loginSuccess(response.data));
      sessionStorage.setItem('username', username);
      // sessionStorage.setItem('bg_token', response.data.bgToken);
      sessionStorage.setItem('jwt_token', response.data.jwtToken);
    } catch (err) {
      dispatch(loginFailed(err.message));
    }
  };
};

export const logout = () => {
  const token = sessionStorage.getItem('jwt_token');
  return async (dispatch: Function) => {
    try {
      await API_V2.post('/account/v2/logout', null, {
        headers: {
          Authorization: token,
        },
      });

      dispatch(logoutSuccess());
    } catch (err) {
      dispatch(logoutFailed(err?.response?.data?.message));
    } finally {
      sessionStorage.clear();
    }
  };
};

export const getSapTokenFromToken = () => {
  if (sessionStorage.getItem('jwt_token')) {
    const claim = sessionStorage.getItem('jwt_token').split('.')[1];
    return JSON.parse(window.atob(claim)).sap;
  }
  return null;
};

const uniqueGuid = (): string => {
  const id = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    id() +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    id() +
    id()
  );
};
