export const InitialTheme: { [key: string]: string } = {
  Sky11: 'light',
  playexch247: 'light',
  playbetexch: 'light',
  indian11: 'light',
  baazifair:"green",
  betfair: 'light',
  fairwins77: 'green',
  shettyonline: 'light',
};

export const ContactList: { [key: string]: string } = {
  playexch247: '7450010508',
};

export const CasinoWhitelabel: { [key: string]: boolean } = {
  skylivecasino: true
}